import React, { FC, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { NotificationCenter } from './RbKit';
import api from './api';
import { hasRole, useProfile } from './lib/auth';
import TopBar from './modules/TopBar';
import Drawer from './modules/Drawer';
import Loader from './RbKit/elements/Loader';
import HospitalListView from './views/admin/HospitalView';
import HospitalEditView from './views/admin/HospitalView/Edit';
import ProductListView from './views/admin/ProductView';
import ProductEditView from './views/admin/ProductView/Edit';
import UserListView from './views/admin/UserView';
import UserEditView from './views/admin/UserView/Edit';
import LoginView from './views/auth/LoginView';
import OrderListView from './views/OrderView';
import OrderEditView from './views/OrderView/Edit';
import styles from './styles.module.scss';
import SettingsView from './views/admin/SettingsView';
import HcpListView from './views/admin/HcpView';
import HcpLimitView from './views/admin/HcpView/Limits';
import AlertListView from './views/admin/AlertView';
import AlertEditView from './views/admin/AlertView/Edit';
import AlertModal from './components/AlertModal';

const App: FC = (): JSX.Element => {
    const [ isLoading, setIsloading ] = useState<boolean>(true);
    const [ routes, setRoutes ] = useState<JSX.Element[]>([]);
    const profile = useProfile();

    const logoutTimer = useRef<any>(null);

    useEffect(() => {
        const logout = () => {
            clearTimeout(logoutTimer.current);

            logoutTimer.current = setTimeout(() => {
                api.logout(profile?.role || 'HCP');
            }, 300000);
        }

        document.addEventListener('click', () => logout(), true);

        return () => clearTimeout(logoutTimer.current);
    }, [profile]);

    useEffect(() => {
        if (!localStorage.getItem('accessToken')) {
            setIsloading(false)
            return;
        }

        api.getProfile().then(() => setIsloading(false)).catch(() => setIsloading(false));
    }, []);

    useEffect(() => {
        if (!profile) {
            return;
        }

        const rr = [
            <Redirect key="route-a1" exact from="/" to="/orders" />,
            <Route key="route-1" component={OrderListView} exact path="/orders" />,
            <Route key="route-2" component={OrderEditView} exact path="/orders/create" />,
            <Route key="route-3" component={OrderEditView} exact path="/orders/:id/edit" />,
        ];
        
        if (hasRole(profile, 'Administrator')) {
            rr.push(<Route key="route-a2" component={HospitalListView} exact path="/admin/hospitals" />);
            rr.push(<Route key="route-a3" component={HospitalEditView} exact path="/admin/hospitals/create" />);
            rr.push(<Route key="route-a4" component={HospitalEditView} exact path="/admin/hospitals/:id/edit" />);

            rr.push(<Route key="route-a5" component={ProductListView} exact path="/admin/products" />);
            rr.push(<Route key="route-a6" component={ProductEditView} exact path="/admin/products/create" />);
            rr.push(<Route key="route-a7" component={ProductEditView} exact path="/admin/products/:id/edit" />);

            rr.push(<Route key="route-a14" component={AlertListView} exact path="/admin/alerts" />);
            rr.push(<Route key="route-a15" component={AlertEditView} exact path="/admin/alerts/create" />);
            rr.push(<Route key="route-a16" component={AlertEditView} exact path="/admin/alerts/:id/edit" />);

            rr.push(<Route key="route-a8" component={UserListView} exact path="/admin/users" />);
            rr.push(<Route key="route-a9" component={UserEditView} exact path="/admin/users/create" />);
            rr.push(<Route key="route-a10" component={UserEditView} exact path="/admin/users/:id/edit" />);

            rr.push(<Route key="route-a11" component={SettingsView} exact path="/admin/settings" />);

            rr.push(<Route key="route-a12" component={HcpListView} exact path="/admin/hcps" />);

            rr.push(<Route key="route-a13" component={HcpLimitView} exact path="/admin/hcps/:id/limits" />);
        }

        setRoutes(rr);
    }, [profile]);

    return (
        <Router>
            <TopBar />
            {isLoading ? (
                <Loader />
            ) : profile ? (<>
                <Drawer />
                <main className={styles.main}>
                    <div>
                    <Switch>
                        {routes}
                    </Switch>
                    </div>
                </main>
                <AlertModal />
            </>) : (
                <Switch>
                    <Route component={LoginView} path="*" />
                </Switch>
            )}
            <NotificationCenter />
        </Router>
    )
}

export default App;
