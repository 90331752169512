import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import api from '../../api';
import { useProfile } from '../../lib/auth';
import logo from './logo.svg';
import styles from './styles.module.scss';
import { useTrans } from '../../lib/trans';

const getShortName = (name: string): string => {
    const parts = name.split(' ');
    return `${parts[0][0]}${parts[parts.length - 1][0]}`;
}

const TopBar: FC = (): JSX.Element => {
    const profile = useProfile();
    const __ = useTrans();

    const logout = (): void => {
        if (window.confirm(__('Are you sure you wish to logout?'))) {
            api.logout(profile?.role || 'HCP');
        }
    }

    return (
        <div className={styles.container}>
            <div>
                <h1>{process.env.REACT_APP_APPLICATION_NAME}</h1>
            </div>
            {profile && (
                <div className={styles.account}>
                    <div
                        className={styles.avatar}
                        onClick={logout}
                    >
                        {getShortName(`${profile.firstName || profile.hcp?.name} ${profile.lastName || profile.hcp?.name}`)}
                    </div>
                </div>
            )}
            <img alt="Roche" className={styles.logo} src={logo} />
        </div>
    );
}

export default TopBar;
