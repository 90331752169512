import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import en from './translations/en.json';
import it from './translations/it.json';
import './RbKit/index.scss';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'it',
    resources: {
        en: { common: en },
        it: { common: it },
    }
});

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18next}>
            <Provider store={store}>
                <App />
            </Provider>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
