import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Grid, PageHeader, Segment, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiHospital } from '../../../api/hospital';
import ChangeHandler from '../../../components/ChangeHandler';
import { useTrans } from '../../../lib/trans';

interface HospitalEditProps extends RouteComponentProps<{ id?: string }> {
}

const HospitalEditView: FC<HospitalEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ hospital, setHospital ] = useState<Partial<ApiHospital>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const __ = useTrans('hospitals');

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getHospital(parseInt(id)).then(({ data }) => {
                setHospital(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setHospital({
            ...hospital,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putHospital(hospital).then(() => {
            setIsLoading(false);
            setChangesMade(false);
            toast(__('Hospital saved succesfully'));
            history.push('/admin/hospitals');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast(__('Something went wrong', ''), 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/settings': __('Settings', 'drawer'),
                '/admin/hospitals': __('Hospitals'),
                [`/admin/hospitals/${id ? `${id}/edit` : 'create'}`]: id ? hospital.name || 'Nuovo' : 'Nuovo',
            }}
            title={`${id ? 'Modifica' : 'Nuovo'} ospedale`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Input
                    error={errors.uci}
                    label="UCI"
                    name="uci"
                    onChange={handleInput}
                    required
                    value={hospital.uci || ''}
                />
                <Form.Input
                    error={errors.name}
                    label={__('Name')}
                    name="name"
                    onChange={handleInput}
                    required
                    value={hospital.name || ''}
                />
                <Form.Input
                    error={errors.address}
                    label={__('Address')}
                    name="address"
                    onChange={handleInput}
                    required
                    value={hospital.address || ''}
                />
                <Grid.Row>
                    <Grid.Column md={4}>
                        <Form.Input
                            error={errors.zipcode}
                            label={__('Zipcode')}
                            name="zipcode"
                            onChange={handleInput}
                            required
                            value={hospital.zipcode || ''}
                        />
                    </Grid.Column>
                    <Grid.Column md={8}>
                        <Form.Input
                            error={errors.city}
                            label={__('City')}
                            name="city"
                            onChange={handleInput}
                            required
                            value={hospital.city || ''}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Segment>
        </Form>
    </>);
}

export default HospitalEditView;
