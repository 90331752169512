import React, { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PaddingType } from '../../definitions';
import styles from './styles.module.scss';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    block?: boolean,
    disabled?: boolean,
    href?: string,
    icon?: any,
    label?: string,
    link?: boolean,
    padding?: PaddingType,
    primary?: boolean,
    target?: string,
    trigger?: boolean,
    triggered?: boolean,
    type?: string,
}

const Button: FC<ButtonProps> = ({
    block,
    children,
    className,
    disabled,
    href,
    icon,
    label,
    link,
    padding,
    primary,
    target,
    trigger,
    triggered,
    type,
    onClick,
    ...props
}) => {
    const elemProps: any = {
        className: [
            styles.base,
            block && styles.block,
            disabled && styles.disabled,
            link && styles.link,
            (trigger || padding) && styles[`${trigger ? 'dense' : padding}Padding`],
            primary && styles.primary,
            trigger && styles.trigger,
            triggered && styles.triggered,
            className,
        ].join(' '),
        onClick: disabled ? undefined: onClick,
        ...props
    }

    const innerContent = icon ? (<>
        <FontAwesomeIcon icon={icon as any} />
        {(children || label) && (<span>{children || label}</span>)}
    </>) : (children || label);

    if (href) {
        if (target) {
            elemProps.href = href;
            elemProps.target = target;
            elemProps.rel = 'noopener noreferrer';
            return <a {...elemProps}>{innerContent}</a>;
        } else {
            elemProps.to = href;
            return <Link {...elemProps}>{innerContent}</Link>;
        }
    }

    return <button {...elemProps} type={type || 'button'}>{innerContent}</button>
}

export default Button;
