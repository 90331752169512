import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { Button, Checkbox, Form, PageHeader, Segment } from '../../RbKit';
import styles from './styles.module.scss';
import { useTrans } from '../../lib/trans';

interface DisclaimerProps {
    onUpdate: (n: number) => void,
}

const Disclaimer: FC<DisclaimerProps> = ({ onUpdate }): JSX.Element => {
    const [ agree, setAgree ] = useState<boolean>(false);
    const [ step, setStep ] = useState<number>(4);
    const __ = useTrans('disclaimer');
    
    useEffect(() => {
        onUpdate(step);
    }, [step]); // eslint-disable-line

    return (<div className="container-small">
        <PageHeader
            title={__('Please fill out this form to order samples')}
            breadcrumb={{
                '/orders': __('Orders', 'orders'),
                '/orders/create/disclaimer': __('Disclaimer'),
            }}
        />
        <Form>
            {step === 4 && (
                <Segment className={styles.disclaimer}>
                    <h2 style={{ marginBottom: 5 }}>Campioni Gratuiti</h2>
                    <div className={styles.checkbox}>
                        <Checkbox
                            checked={agree}
                            onChange={({ checked }: any) => setAgree(checked)}
                            label="Si prende atto che i campioni gratuiti di un medicinale possono essere rimessi ai medici autorizzati a prescriverlo in conformità a quanto disposto sia dal D. Lgs. n. 219/2006 sia dalle disposizioni del Codice Deontologico di Farmindustria ai quali si rimanda."
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={() => setStep(5)}
                            primary
                            disabled={!agree}
                            label="Continua"
                        />
                    </div>
                </Segment>      
            )}
        </Form>
    </div>);
}

export default Disclaimer;
