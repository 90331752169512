import axios, { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse } from './';
import { ApiHospital } from './hospital';

export interface ApiProductDosage {
    id?: number,
    productId?: number,
    materialNumber?: string,
    dosage: string,
    maxAmount: number,
}

export interface ApiProduct {
    id: number,
    classification: 'A' | 'B',
    commercializationDate?: string,
    name: string,
    genericName: string,
    link?: string,
    information?: string,
    form: string,
    dosages: ApiProductDosage[],
    isActive: boolean,
    isAvailable: boolean,
    file?: string,
    fileName?: string,
    hasFile?: number,
    users?: number[],
    availableMessage?: string,
    hospitals?: Partial<ApiHospital>[],
    whitelist?: string,
    blacklist?: string,
}

const rest = {
    autocomplete: (query?: string): AxiosPromise<ApiPaginatedResponse<ApiProduct[]>> => {
        return axios.get(`products?query=${query || ''}`);
    },
    delete: (productId: number | string): AxiosPromise => {
        return axios.delete(`admin/products/${productId}`);
    },
    get: (productId: number): AxiosPromise<ApiProduct> => {
        return axios.get(`admin/products/${productId}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiProduct[]>> => {
        return axios.get(`admin/products?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (product: Partial<ApiProduct>): AxiosPromise<ApiProduct> => {
        return axios.post(`admin/products${product.id ? `/${product.id}` : ''}`, product);
    },
    putFile: (productId: number, file: File): AxiosPromise => {
        const fd = new FormData();
        fd.append('file', file);

        return axios.post(`admin/products/${productId}/file`, fd);
    }
}

export default rest;
