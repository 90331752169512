import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Form, PageHeader, Segment, Table, toast } from '../../../RbKit';
import { request } from '../../../api';
import { useTrans } from '../../../lib/trans';

const HcpLimitView: FC = (): JSX.Element => {
    const { id } = useParams() as any;
    const [ isLoading, setIsLoading ] = useState(false);
    const [ dosages, setDosages ] = useState<any[]>([]);
    const __ = useTrans('hcps');
    
    const fetch = useCallback((): void => {
        if (!id) return;
        setIsLoading(true);

        request.get(`/admin/hcps/${id}/limits`).then(({ data }) => {
            setDosages(data.data);
            setIsLoading(false);
        });
    }, [id]);
    useEffect(fetch, [fetch]);

    const handleLimit = (index: number, value: number): void => {
        const newDosages = [ ...dosages ];
        newDosages[index].amount = value;
        setDosages(newDosages);
    };

    const save = (): void => {
        request.post(`/admin/hcps/${id}/limits`, { dosages }).then(() => {
            toast(__('Limits saved'));
        });
    }

    return (<>
        <PageHeader
            title="Hcp limits"
            breadcrumb={{
                '/admin/settings': __('Settings', 'drawer'),
                '/admin/hcps': __('Hcps'),
                [`/admin/hcps/${id}/limits`]: __('Limits')
            }}
        >
            <Button
                primary
                label={__('Save', '')}
                onClick={save}
            />
        </PageHeader>

        <Segment isLoading={isLoading}>
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            {__('Product')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {__('Form')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {__('Dosage')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {__('Materialno.')}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing style={{ minWidth: 120 }}>
                            {__('Manual limit')}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            {__('SR limit')}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            {__('Total limit')}
                        </Table.HeaderCell>
                    </Table.Row>
                </thead>
                <tbody>
                    {dosages.map((dosage, index) => (
                        <Table.Row key={`row-${index}`}>
                            <Table.Cell>
                                {dosage.name} {dosage.genericName}
                            </Table.Cell>
                            <Table.Cell>
                                {dosage.form}
                            </Table.Cell>
                            <Table.Cell>
                                {dosage.dosage}
                            </Table.Cell>
                            <Table.Cell>
                                {dosage.materialNumber}
                            </Table.Cell>
                            <Table.Cell>
                                <Form.Input
                                    label={__('Amount')}
                                    type="number"
                                    min={0}
                                    max={8 - dosage.sr}
                                    value={dosage.amount}
                                    onChange={({ value }: any) => handleLimit(index, value)}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                {dosage.sr}
                            </Table.Cell>
                            <Table.Cell collapsing align="right">
                                <span style={parseInt(dosage.sr) + parseInt(dosage.amount) >= 8 ? {
                                    fontWeight: 'bold', color: '#dd0000',
                                } : {}}>
                                    {parseInt(dosage.sr) + parseInt(dosage.amount)}
                                </span>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </tbody>
            </Table>
        </Segment>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                primary
                label={__('Save', '')}
                onClick={save}
            />
        </div>
    </>);
}

export default HcpLimitView;
