import { faCog, faFileAlt, faPlus } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { hasRole, useProfile } from '../../lib/auth';
import { Menu, Segment } from '../../RbKit';
import { useWindowResize } from '../../RbKit/lib/hooks';
import styles from './styles.module.scss';
import { useTrans } from '../../lib/trans';

export const collapse = (state?: boolean) => {
    dispatchEvent(new CustomEvent('toggle-drawer', { detail: { state }}));
}

export const useCollapse = () => {
    const [ isCollapsed, setIsCollapsed ] = useState(false);

    useEffect(() => {
        const handleCollapse = (e: any) => {
            if (e.detail && e.detail.state !== undefined) {
                setIsCollapsed(e.detail.state);
            } else {
                setIsCollapsed(!isCollapsed);
            }
        };
        window.addEventListener('toggle-drawer', handleCollapse);
        return () => window.removeEventListener('toggle-drawer', handleCollapse);
    });

    return isCollapsed;
}

const Drawer: FC = (): JSX.Element => {
    const isCollapsed = useCollapse();
    const location = useLocation();
    const profile = useProfile();
    const windowCollapse = () => collapse(window.innerWidth <= 992);
    useWindowResize(windowCollapse, 100);
    useEffect(windowCollapse, []);
    const __ = useTrans('drawer');
    
    return (
        <Segment
            className={[
                styles.wrapper,
                isCollapsed && styles.isCollapsed,
            ].join(' ')}
            padding="none"
        >
            <div className={styles.base}>
                {(hasRole(profile, 'Administrator') || hasRole(profile, 'PJP')) ? (
                    <Menu>
                        <Menu.Header>
                            {__(hasRole(profile, 'PJP') ? 'Orders' : 'Administrator')}
                        </Menu.Header>
                        {hasRole(profile, 'PJP') && (
                            <Menu.Item
                                active={location.pathname.includes('orders/create')}
                                className={styles.menuItem}
                                content={__('New order')}
                                href="/orders/create"
                                icon={faPlus as any}
                            />
                        )}
                        <Menu.Item
                            active={location.pathname.includes('orders')}
                            className={styles.menuItem}
                            content={__('Order overview')}
                            icon={faFileAlt as any}
                            href="/orders"
                        />
                        {!hasRole(profile, 'PJP') && (
                            <Menu.Item
                                active={/admin\/(settings|users|products|hospitals)/.test(location.pathname)}
                                className={styles.menuItem}
                                content={__('Settings')}
                                icon={faCog as any}
                                href="/admin/settings"
                            />
                        )}
                    </Menu>
                ) : (
                    <Menu>
                        <Menu.Header>
                            I miei ordini
                        </Menu.Header>
                        <Menu.Item
                            active={location.pathname.includes('orders/create')}
                            className={styles.menuItem}
                            content={__('New order')}
                            href="/orders/create"
                            icon={faPlus as any}
                        />
                        <Menu.Item
                            active={location.pathname === '/orders'}
                            className={styles.menuItem}
                            content={__('Order overview')}
                            icon={faFileAlt as any}
                            href="/orders"
                        />
                    </Menu>
                )}
            </div>
        </Segment>
    );
}

export default Drawer;
