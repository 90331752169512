import { faPlus, faPencil, faTrashAlt, faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiAlert } from '../../../api/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTrans } from '../../../RbKit/lib/hooks';

const AlertListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ alerts, setAlerts ] = useState<ApiAlert[]>([]);
    const __ = useTrans('alerts');

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listAlerts({ query, page }).then(({ data }) => {
            setMeta(data.meta);
            setAlerts(data.data);
            setIsLoading(false);
        });
    }

    const deleteAlert = (alertId: number | string): void => {
        api.deleteAlert(alertId).then(() => {
            fetch();
            toast(__('Alert deleted successfully'));
        });
    }

    return (<>
        <PageHeader
            title={__('Alerts')}
            breadcrumb={{
                '/admin/settings': __('Settings', 'drawer'),
                '/admin/alerts': __('Alerts')
            }}
        >
            <Button
                href="/admin/alerts/create"
                icon={faPlus as any}
                padding="compact"
                primary
            />
        </PageHeader>

        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            {__('Name')}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="center">
                            {__('Is active')}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {alerts.length > 0 ? alerts.map((alert) => (
                        <Table.Row key={`row-${alert.id}`}>
                            <Table.Cell primary name="Name">
                                <Link to={`/admin/alerts/${alert.id}/edit`}>
                                    {alert.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell name="Is active" collapsing align="center">
                                <FontAwesomeIcon
                                    icon={alert.isActive ? faCheck : faTimes as any}
                                    className={alert.isActive ? 'success' : 'error'}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil as any}
                                        content={__('Edit', '')}
                                        href={`/admin/alerts/${alert.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Sei sicuro di voler eliminare questo avviso? Questa azione non può essere annullata"
                                        onConfirm={() => deleteAlert(alert.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt as any}
                                            content={__('Delete', '')}
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default AlertListView;
