import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, PageHeader, Segment, toast } from '../../../RbKit';
import api, { request } from '../../../api';
import { ApiUser } from '../../../api/user';
import ChangeHandler from '../../../components/ChangeHandler';
import { useTrans } from '../../../lib/trans';

interface UserEditProps extends RouteComponentProps<{ id?: string }> {
}

const UserEditView: FC<UserEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ user, setUser ] = useState<Partial<ApiUser>>({});
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ regions, setRegions ] = useState<any[]>([]);
    const __ = useTrans('users');

    useEffect(() => {
        request.get('admin/regions').then(({ data }) => setRegions(data));
    }, []);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getUser(id).then(({ data }) => {
                setUser(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setUser({
            ...user,
            [name]: value,
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putUser({
            ...user,
            role: user.role || 'Administrator',
        }).then(() => {
            setIsLoading(false);
            setChangesMade(false);
            toast(__('User saved succesfully'));
            history.push('/admin/users');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast(__('Something went wrong', ''), 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/settings': __('Settings', 'drawer'),
                '/admin/users': __('Users'),
                [`/admin/users/${id ? `${id}/edit` : 'create'}`]: id ? user.firstName || 'Nuovo' : 'Nuovo',
            }}
            title={`${id ? 'Modifica' : 'Nuovo'} utente`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Segment isLoading={isLoading}>
                <Form.Dropdown
                    label={__('Role')}
                    name="role"
                    onChange={handleInput}
                    options={[{
                        text: 'Administrator',
                        value: 'Administrator',
                    }, {
                        text: 'Field',
                        value: 'PJP',
                    }]}
                    required
                    value={user.role || 'Administrator'}
                />
                <Form.Input
                    error={errors.firstName}
                    label={__('First name')}
                    name="firstName"
                    onChange={handleInput}
                    required
                    value={user.firstName || ''}
                />
                <Form.Input
                    error={errors.lastName}
                    label={__('Last name')}
                    name="lastName"
                    onChange={handleInput}
                    required
                    value={user.lastName || ''}
                />
                <Form.Input
                    error={errors.email_address}
                    label={__('Email address')}
                    name="emailAddress"
                    onChange={handleInput}
                    required
                    value={user.emailAddress || ''}
                />
                {user.role === 'PJP' && (
                    <Form.Dropdown
                        label={__('Region(s)')}
                        name="regions"
                        options={regions}
                        multiple
                        onChange={handleInput}
                        value={user.regions}
                    />
                )}
            </Segment>
        </Form>
    </>);
}

export default UserEditView;
