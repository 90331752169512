import React, { FC, HTMLAttributes, useState } from 'react';
import Button from '../../elements/Button';
import Modal from '../Modal';

interface ConfirmProps {
    content?: string | JSX.Element,
    header?: string | JSX.Element,
    onCancel?: () => void,
    onConfirm?: () => void,
    trigger: JSX.Element,
}

const Confirm: FC<ConfirmProps & HTMLAttributes<HTMLDivElement>> = ({
    children,
    content,
    header,
    onCancel,
    onConfirm,
    trigger,
    ...props
}): JSX.Element => {
    const [ open, setOpen ] = useState(false);

    const handleCancel = (): void => {
        setOpen(false);
        if (onCancel) {
            onCancel();
        }
    }

    const handleConfirm = (): void => {
        setOpen(false);
        if (onConfirm) {
            onConfirm();
        }
    }

    return (<>
        {React.cloneElement(trigger, { ...props, onClick: () => setOpen(true) })}
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="No"
                        link
                        onClick={() => handleCancel()}
                    />
                    <Button
                        label="Sì"
                        primary
                        onClick={() => handleConfirm()}
                    />
                </div>
            )}
            header={header || 'Avviso'}
            onClose={() => handleCancel()}
            open={open}
            size="small"
        >
            {children || content}
        </Modal>
    </>)
}

export default Confirm;
