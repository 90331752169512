import { faFilePdf, faPlus, faTrashAlt, faUpload } from '@fortawesome/pro-light-svg-icons';
import { faCaretRight, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import React, { FC, useEffect, useState, RefObject, createRef } from 'react';
import { Link } from 'react-router-dom';
import api, { getToken, ApiMeta } from '../../api';
import { ApiOrder } from '../../api/order';
import { useProfile } from '../../lib/auth';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast } from '../../RbKit';
import Tag from '../../RbKit/elements/Tag';
import styles from './styles.module.scss';
import { useTrans } from '../../lib/trans';

const OrderListView: FC = (): JSX.Element => {
    const fileInputRef: RefObject<HTMLInputElement> = createRef();
    const [ active, setActive ] = useState<string>();
    const [ orders, setOrders ] = useState<ApiOrder[]>([]);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ upload, setUpload ] = useState<string>();
    const profile = useProfile();
    const __ = useTrans('orders');

    const fetch = (query?: string, page: number = 1) => {
        api.listOrders({ query, page }).then(({ data }) => {
            setOrders(data.data);
            setMeta(data.meta);
        });
    }

    useEffect(fetch, []);

    const deleteOrder = (orderId: string): void => {
        api.deleteOrder(orderId).then(() => {
            fetch();
            toast(__('Order cancelled successfully'));
        });
    }

    const startFileUpload = (orderId: string): void => {
        setUpload(orderId);
        fileInputRef.current?.click();
    }

    const handleFileUpload = (e: any): void => {
        if (!upload) return;
        api.uploadSignature(upload, e.target.files[0]).then(() => {
            setUpload(undefined);
            toast(__('Document uploaded succesfully'));
            fetch();
        }).catch(() => {
            window.alert(__('Please upload a PDF file'));
        });
    }

    return (<>
        <input
            accept="application/pdf"
            onChange={handleFileUpload}
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
        />
        <PageHeader title="Ordini" breadcrumb={{'/admin/users': 'Panoramica ordini'}}>
            {(profile?.role === 'Administrator' || profile?.role === 'PJP') ? (
                <Button
                    label="Export"
                    padding="compact"
                    primary
                    href={`${process.env.REACT_APP_API_BASE_URL}admin/orders/export?_token=${getToken()}`}
                    target="_blank"
                />
            ) : (
                <Button
                    href="/orders/create"
                    icon={faPlus as any}
                    padding="compact"
                    primary
                />
            )}
        </PageHeader>
        <Segment>
            <Table.Actions onSearch={fetch} />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing>
                            {__('Number')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {__('Date')}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Prodotto
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Numerica Prodotto
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            {__('Hospital')}
                        </Table.HeaderCell>
                        {(profile?.role === 'Administrator' || profile?.role === 'PJP') && (<>
                            <Table.HeaderCell>
                                {__('HCP')}
                            </Table.HeaderCell>
                        </>)}
                        <Table.HeaderCell>
                            {__('Status')}
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell collapsing /> */}
                        <Table.HeaderCell collapsing />
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {orders.map((order) => (<>
                        <Table.Row
                            key={order.id}
                            // className={active === order.id ? styles.active : ''}
                        >
                            <Table.Cell collapsing primary name="Number">
                                <Link to={`/orders/${order.id}/edit`}>
                                    {order.number}
                                </Link>
                            </Table.Cell>
                            <Table.Cell name="Date">
                                {order.createdAt}
                            </Table.Cell>
                            <Table.Cell name="Amount">
                                {order.products && order.products.length > 0 ? `${order.products.map((o) => o.product.name).join(', ')} ${order.products[0].product.form || ''}` : ''}
                            </Table.Cell>
                            <Table.Cell name="Amount">
                                {order.products?.length}
                            </Table.Cell>
                            <Table.Cell name="Hospital">
                                {order.hospital}
                            </Table.Cell>
                            {(profile?.role === 'Administrator' || profile?.role === 'PJP') && (<>
                                <Table.Cell name="HCP">
                                    {order.hcp?.name}
                                </Table.Cell>
                            </>)}
                            <Table.Cell name="Amount">
                                <Tag
                                    success={order.status === 2}
                                    warning={order.status === 1}
                                    label={(order.statusName === 'Needs signature' ? 'Nuovo' : (order.statusName || 'Nuovo')).toUpperCase()}
                                />
                            </Table.Cell>
                            {/* <Table.Cell collapsing>
                                {order.needsSignature && (profile?.role === 'Administrator' || profile?.role === 'PJP') ? (
                                    <Button
                                        icon={faUpload as any}
                                        onClick={() => startFileUpload(order.id)}
                                    />
                                ) : (
                                    <Confirm
                                        content="Are you sure you wish to upload a new document? this action will replace any previously uploaded document"
                                        onConfirm={() => startFileUpload(order.id)}
                                        trigger={<Button
                                            icon={faUpload as any}
                                        />}
                                    />
                                )}
                            </Table.Cell> */}
                            <Table.Cell collapsing actions>
                                <Button
                                    trigger
                                    icon={active === order.id ? faCaretDown : faCaretRight as any}
                                    onClick={() => setActive(order.id)}
                                />
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    {order.signedAt && (<>
                                        <Menu.Item
                                            content={__('Download signed form')}
                                            icon={faFilePdf as any}
                                        >
                                            <a
                                                href={`${process.env.REACT_APP_API_BASE_URL}orders/${order.id}/download?_token=${getToken()}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {__('Download signed form')}
                                            </a>
                                        </Menu.Item>
                                    </>)}
                                    <Menu.Item
                                        content={__('Download form')}
                                        icon={faFilePdf as any}
                                    >
                                        <a
                                            href={`${process.env.REACT_APP_API_BASE_URL}orders/${order.id}/download?_token=${getToken()}&original=1`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {__('Download form')}
                                        </a>
                                    </Menu.Item>
                                    {(profile?.role === 'Administrator' || profile?.role === 'PJP') && (<>
                                        <Confirm
                                            content="Sei sicuro di voler annullare questo ordine? Questa azione non può essere annullata."
                                            onConfirm={() => deleteOrder(order.id)}
                                            trigger={<Menu.Item
                                                icon={faTrashAlt as any}
                                                content={__('Cancel order')}
                                            />}
                                        />
                                    </>)}
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                        {order.products?.map((op, index) => (
                            <Table.Row
                                key={`${order.id}-${index}-op`}
                                className={`${styles.subrow} ${active !== order.id ? styles.hidden : ''}`}
                            >
                                <Table.Cell collapsing primary name="Number">
                                    &nbsp;
                                </Table.Cell>
                                <Table.Cell name="product">
                                    <b>{op.product.name} {op.product.form}</b><br />
                                    {op.product.link && op.product.link.includes('https:') && (
                                        <a
                                            href={op.product.link}
                                            rel="noopenerer noreferrer"
                                            target="_blank"
                                        >
                                            {__('Product details & dosage calc.')}
                                        </a>
                                    )}
                                </Table.Cell>
                                <Table.Cell name="Amount">
                                    <span>{__('Amount')}</span>{' '}{op.amount}
                                </Table.Cell>
                                <Table.Cell collapsing name="Dosage">
                                    <span>{__('Dosage', 'products')}</span>{' '}{op.productDosage.dosage}
                                </Table.Cell>
                                {(profile?.role === 'Administrator' || profile?.role === 'PJP') && (<>
                                    <Table.Cell collapsing name="HCP">
                                        &nbsp;
                                    </Table.Cell>
                                    <Table.Cell collapsing name="Amount">
                                        &nbsp;
                                    </Table.Cell>
                                </>)}
                                <Table.Cell collapsing actions>
                                    &nbsp;
                                </Table.Cell>
                                <Table.Cell collapsing actions />
                            </Table.Row>
                        ))}
                    </>))}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default OrderListView;
