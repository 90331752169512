import { useTranslation } from 'react-i18next';

export const useTrans = (section?: string): (
    key: string,
    section?: string,
    replacement?: any[],
) => string => {
    const { t } = useTranslation('common');

    return (
        key: string,
        s?: string,
        replacement?: any[],
    ): string => {
        let str = '';

        if (s || s === '') {
            str = t(`${s ? s + '.' : 'general.'}${key}`);
        } else {
            str = t(`${section ? section + '.' : 'general.'}${key}`); 
        }

        if (replacement) {
            replacement.forEach((o, i) => {
                const reg = new RegExp(`\\$${i}`, 'g');
                str = str.replace(reg, o);
            })
        }

        return str;
    };
}
