import { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

export const useDebounceSearch = (callback?: (query: string) => void, delay: number = 300) => {
    const [ debounceSearch ] = useState(() => debounce(callback ? callback : () => {}, delay));
    return debounceSearch;
};

export const useHandleKeyUp = (callback: (keyCode: number, e: Event) => void, keys: number[]) => {
    useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            if (keys.includes(e.keyCode)) {
                callback(e.keyCode, e);
            }
        }

        window.addEventListener('keyup', handleKeyUp);
        return () => window.removeEventListener('keyup', handleKeyUp);
    });
}

export function useRefState<T>(initialValue: T): [{ current: T }, (newValue: T) => void] {
    const [ value, setValue ] = useState<T>(initialValue);
    const realValue = useRef<T>(value);

    const setRealValue = (newValue: T): void => {
        realValue.current = newValue;
        setValue(newValue);
    }

    return [realValue, setRealValue];
}

export const useWindowResize = (callback: (e: Event) => void, delay: number = 300) => {
    useEffect(() => {
        let resizeTimer: NodeJS.Timeout; 

        const handleResize = (e: Event) => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => callback(e), delay);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
}

export const useWindowScroll = (callback: (e: Event) => void, delay: number = 300, deps: any[] = []) => {
    useEffect(() => {
        let scrollTimer: NodeJS.Timeout; 
        
        const handleScroll = (e: Event) => {
            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => callback(e), delay);
        }

        window.addEventListener('scroll', handleScroll, true);
        return () => window.removeEventListener('scroll', handleScroll, true);
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}

export const useTrans = (section?: string): (
    key: string,
    section?: string,
    replacement?: any[],
) => string => {
    const { t } = useTranslation('common');

    return (
        key: string,
        s?: string,
        replacement?: any[],
    ): string => {
        let str = '';

        if (s || s === '') {
            str = t(`${s ? s + '.' : 'general.'}${key}`);
        } else {
            str = t(`${section ? section + '.' : 'general.'}${key}`); 
        }

        if (replacement) {
            replacement.forEach((o, i) => {
                const reg = new RegExp(`\\$${i}`, 'g');
                str = str.replace(reg, o);
            })
        }

        return str;
    };
}

export const useOnClickOutside = (ref: RefObject<any>, handler: (e: any) => void) => {
    useEffect(() => {
        const listener = (e: any) => {
            if (!ref.current || ref.current.contains(e.target)) {
                return;
            }
            handler(e);
        };

        document.addEventListener('mousedown', listener, true);
        document.addEventListener('touchstart', listener, true);
        return () => {
            document.removeEventListener('mousedown', listener, true);
            document.removeEventListener('touchstart', listener, true);
        };
    }, [ref, handler]);
}
