import React, { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';

interface ItemProps extends HTMLAttributes<HTMLDivElement> {
    active?: boolean,
    as?: string,
    content?: string,
    href?: string,
    icon?: any,
}

const Item: FC<ItemProps> = ({ active, children, className, content, href, icon, ...props }): JSX.Element => {
    const elemProps: any = {
        className: [
            styles.item,
            active && styles.itemIsActive,
            className,
        ].join(' '),
        ...props
    }

    const innerContent = (<>
        {icon && (
            <div className={styles.itemIcon}>
                <FontAwesomeIcon icon={icon as any} />
            </div>
        )}
        <div className={styles.itemContent}>{children || content}</div>
    </>)

    if (href) {
        elemProps.to = href;
        return <Link {...elemProps}>{innerContent}</Link>;
    }

    const Elem = props.as || 'div';
    return <Elem {...elemProps}>{innerContent}</Elem>
}

export default Item;
