import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import api from '../../../api';
import { Button, Form, Modal, Segment, toast } from '../../../RbKit';
import styles from './styles.module.scss';

const LoginView: FC = (): JSX.Element => {
    const [ showError, setShowError ] = useState<string>();
    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ error, setError ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isUser, setIsUser ] = useState<boolean>(false);
    const [ param, setParam ] = useState<string>();
    const history = useHistory();

    useEffect(() => {
        const token = qs.parse(window.location.search.substring(1));
        
		if (token && token._setToken) {
            setIsLoading(true);
            localStorage.setItem('accessToken', token._setToken as string);
            api.getProfile().then(() => {
                history.push('/');
            });
		} else if (/*process.env.NODE_ENV === 'production' && */window.location.pathname !== '/admin' && !token.loggedout) {
            if (!token.checked) {
                window.location.href = `${process.env.REACT_APP_RODIP_URL || ''}`;//?check=1`;
            }

            if (token.err) {
                setShowError(token.err as string);
            }
            if (token.param) {
                setParam(token.param as string);
            }

            setIsUser(true);
        }

        document.querySelector('input')?.focus();
    }, [history]);

    const login = (): void => {
        if (!emailAddress) {
            toast('Si prega di inserire un indirizzo e-mail', 'error');
            setError(true);
            return;
        }

        setError(false);
        setIsLoading(true);
        
        api.login(emailAddress).then(({ data }: any) => {
            setIsLoading(false);
            // window.location.href = data.url;
            toast('È stato inviato un collegamento magico');
        }).catch(() => {
            setError(true);
            setIsLoading(false);
        });
    }

    return (<>
        <div className={styles.container}>
            <div>
                {isUser ? (
                    <Segment isLoading={isLoading} elevate={1}>
                        <h2>Login</h2>
                        <p>
                            Al momento non hai effettuato l'accesso. Fai clic sul pulsante in basso per accedere con il tuo account Roche.
                        </p>
                        <div className={styles.btnContainer}>
                            <Button
                                label="Login"
                                onClick={() => window.location.href = process.env.REACT_APP_RODIP_URL || ''}
                                primary
                            />
                        </div>
                    </Segment>
                ) : (
                    <Segment isLoading={isLoading} elevate={1}>
                        <h2>Login</h2>
                        <p>
                            Al momento non hai effettuato l'accesso. Fai clic sul pulsante in basso per accedere con il tuo account Roche.
                        </p>
                        <div className={styles.btnContainer}>
                            <Button
                                label="Login"
                                onClick={() => window.location.href = `${process.env.REACT_APP_RODIP_URL || ''}?admin=1`}
                                primary
                            />
                        </div>
                    </Segment>
                    // <Segment isLoading={isLoading} elevate={1}>
                    //     <h2>Richiedi un link di accesso</h2>
                    //     <p>
                    //         Inserisci il tuo indirizzo email qui sotto e ti invieremo un link magico per accedere SampleRequest!
                    //     </p>
                    //     <Form.Input
                    //         error={error}
                    //         label="Indirizzo e-mail"
                    //         onChange={({ value }: any) => setEmailAddress(value)}
                    //         value={emailAddress}
                    //     />
                    //     <div className={styles.btnContainer}>
                    //         <Button
                    //             label="Mandami il link"
                    //             onClick={() => login()}
                    //             primary
                    //         />
                    //     </div>
                    // </Segment>
                )}
            </div>
        </div>
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Ok"
                        link
                        onClick={() => setShowError(undefined)}
                    />
                </div>
            )}
            header="Login failure"
            onClose={() => setShowError(undefined)}
            open={showError !== undefined}
            size="small"
        >
            {showError === '100' && <div>Something went wrong, please try again{param ? ` (id: ${param})` : ''}</div>}
            {showError === '101' && <div>Incomplete RochePro registration{param ? ` (id: ${param})` : ''}</div>}
            {showError === '102' && <div>Login failure: missing RIZIV/INAMI number{param ? ` (id: ${param})` : ''}</div>}
            {showError === '103' && <div>RIZIV/INAMI number not authorized{param ? ` (id: ${param})` : ''}</div>}
        </Modal>
    </>);
}

export default LoginView;
