import { faPlus, faPencil, faTrashAlt, faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Confirm, Menu, PageHeader, Segment, Table, toast, useTableSort } from '../../../RbKit';
import api, { ApiMeta } from '../../../api';
import { ApiProduct } from '../../../api/product';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTrans } from '../../../lib/trans';

const ProductListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ orderBy, order, setSorting ] = useTableSort('firstName');
    const [ products, setProducts ] = useState<ApiProduct[]>([]);
    const __ = useTrans('products');

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);

        api.listProducts({ query, page, orderBy, order }).then(({ data }) => {
            setMeta(data.meta);
            setProducts(data.data);
            setIsLoading(false);
        });
    }, [orderBy, order]);
    useEffect(() => fetch(), [orderBy, order, fetch]);

    const deleteProduct = (productId: number | string): void => {
        api.deleteProduct(productId).then(() => {
            fetch();
            toast(__('Product deleted successfully'));
        });
    }

    return (<>
        <PageHeader
            title={__('Products')}
            breadcrumb={{
                '/admin/settings': __('Settings', 'drawer'),
                '/admin/products': __('Products'),
            }}
        >
            <Button
                href="/admin/products/create"
                icon={faPlus as any}
                padding="compact"
                primary
            />
        </PageHeader>

        <Segment isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell collapsing align="center">
                            {__('Available')}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={[orderBy, 'name']}
                            order={order}
                            onSort={setSorting}
                        >
                            {__('Brand name')}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            sorted={[orderBy, 'genericName']}
                            order={order}
                            onSort={setSorting}
                        >
                            {__('Generic name')}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            Class
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing>
                            {__('Form')}
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing align="right">
                            # Dosaggi
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {products.length > 0 ? products.map((product) => (
                        <Table.Row key={`row-${product.id}`}>
                            <Table.Cell name="Available" collapsing align="center">
                                <FontAwesomeIcon
                                    icon={product.isActive ? faCheck : faTimes as any as any}
                                    className={product.isActive ? 'success' : 'error'}
                                />
                            </Table.Cell>
                            <Table.Cell primary name="Brand name">
                                <Link to={`/admin/products/${product.id}/edit`}>
                                    {product.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell name="Generic name">
                                {product.genericName}
                            </Table.Cell>
                            <Table.Cell collapsing name="Classification">
                                {product.classification}
                            </Table.Cell>
                            <Table.Cell collapsing name="Form">
                                {product.form}
                            </Table.Cell>
                            <Table.Cell collapsing name="# Dosages" align="right">
                                {product.dosages.length}
                            </Table.Cell>
                            <Table.Cell collapsing actions>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil as any}
                                        content={__('Edit', '')}
                                        href={`/admin/products/${product.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Confirm
                                        content="Sei sicuro di voler eliminare questo prodotto? Questa azione non può essere annullata"
                                        onConfirm={() => deleteProduct(product.id)}
                                        trigger={<Menu.Item
                                            icon={faTrashAlt as any}
                                            content={__('Delete', '')}
                                        />}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default ProductListView;
